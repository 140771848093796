import React from "react"
import cx from "classnames"

// Components
import Container from "../../../../ui-kit/Container/Container"
import Row from "../../../../ui-kit/Row/Row"
import Button from "../../../../ui-kit/Button/Button"
import { useTabletOrThinner } from "../../../../utils/mediaQuery"

// Styles
import * as styles from "./FirstScreenLight.module.sass"
import LazyHydrate from "react-lazy-hydration"

const FirstScreen = ({
  title,
  description,
  goToMarketplace,
  goToMarketplaceBtn,
  goToTradingDesk,
  goToTradingDeskBtn,
}) => {
  const isTabletOrThinner = useTabletOrThinner()

  return (
    <div className={styles.block}>
      <Container>
        <Row className={styles.row} theme="nowrap">
          <div className={styles.item}>
            <LazyHydrate ssrOnly>
              <h1>{title}</h1>
              <p>{description}</p>
              <div className={styles.container}>
                <div className={styles.directive}>
                  <h3 className={styles.directiveTitle}>{goToMarketplace}</h3>
                  <div className={styles.button}>
                    <Button
                      theme={cx({
                        ["small"]: isTabletOrThinner,
                      })}
                      link="https://marketplace.zionodes.com/"
                      external
                    >
                      {goToMarketplaceBtn}
                    </Button>
                  </div>
                </div>
                <div className={styles.directive}>
                  <h3 className={styles.directiveTitle}>{goToTradingDesk}</h3>
                  <div className={styles.button}>
                    <Button
                      theme={cx("secondary", {
                        ["small"]: isTabletOrThinner,
                      })}
                      link="https://trade.zionodes.com/"
                      external
                    >
                      {goToTradingDeskBtn}
                    </Button>
                  </div>
                </div>
              </div>
            </LazyHydrate>
          </div>
          <div className={styles.item}>
            <video autoPlay="autoplay" loop muted playsInline>
              <source src={"/media/video/zionodes.mp4"} type="video/mp4" />
            </video>

            {/*<Zionodes />*/}
            {/*<div className={styles.mobile}>*/}
            {/*  <ZionodesMobile />*/}
            {/*</div>*/}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default FirstScreen
