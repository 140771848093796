import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import cx from "classnames"
import { debounce } from "lodash"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"

// Icons
import ArrowSkip from "../../../icons/light/arrow-button.svg"

// Styles
import * as styles from "./benefits.module.sass"

import { useCurrentTheme } from "../../../utils/themeChanger"
import LazyHydrate from "react-lazy-hydration"

const benefitsImagesAspectRatio = [
  { width: 79, height: 79 },
  { width: 73, height: 75 },
  { width: 76, height: 75 },
  { width: 55, height: 77 },
  { width: 87, height: 79 },
  { width: 48, height: 79 },
]

const Benefits = ({ data, header, skip }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const container = useRef(null)

  const [elementInfo, setElementInfo] = useState({
    offset: 0,
    height: 0,
  })

  useLayoutEffect(() => {
    if (!container.current) return null
    setElementInfo({
      offset: container.current.offsetTop,
      height: container.current.clientHeight,
    })
  }, [])

  const [direction, setDirection] = useState("bottom")

  const getCoords = elem => {
    const box = elem.getBoundingClientRect()
    return box.top + window.pageYOffset
  }

  const isBottom = el => {
    return el && el.getBoundingClientRect().bottom <= window.innerHeight
  }

  const isTop = el => {
    return el && el.getBoundingClientRect().top >= -100
  }

  const handleScroll = () => {
    const wrappedElement = container.current

    if (isBottom(wrappedElement)) {
      setDirection("top")
    }
    if (isTop(wrappedElement)) {
      setDirection("bottom")
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  const scrollBottom = () => {
    if (direction === "bottom") {
      window.scrollTo({
        top:
          getCoords(container.current) +
          elementInfo.height -
          document.querySelector(`.${styles.card}:last-of-type`).clientHeight +
          document.querySelector(
            `.${styles.card}:last-of-type .${styles.inner}`
          ).clientHeight -
          (windowWidth <= 1366 ? 200 : 100),
        behavior: "smooth",
      })
    } else {
      window.scrollTo({
        top: getCoords(container.current) - 100,
        behavior: "smooth",
      })
    }
  }

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowWidth(window.innerWidth)
      if (!container.current) return null
      setElementInfo({
        offset: container.current.offsetTop,
        height: container.current.clientHeight,
      })
    }, 1000)

    debouncedHandleResize()
    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })

  return (
    <div className={styles.content} ref={container}>
      <Container>
        <Row theme="flex-start">
          <div className={styles.heading}>
            <h2 dangerouslySetInnerHTML={{ __html: header }} />
            <button
              className={cx(styles.button, {
                [styles.revert]: direction === "top",
              })}
              onClick={() => scrollBottom()}
              type="button"
            >
              {skip}
              <img alt="Skip" src={ArrowSkip} />
            </button>
          </div>
          <div className={styles.cards}>
            {data.map((benefit, index) => (
              <div className={styles.card} key={benefit.id}>
                <StyledCard theme={"reverse"}>
                  <div className={styles.inner}>
                    <div className={styles.icon}>
                      <StyledCard theme={"circle"}>
                        <div className={styles.iconContent}>
                          <img
                            height={benefitsImagesAspectRatio[index].height}
                            width={benefitsImagesAspectRatio[index].width}
                            src={
                              isLightTheme
                                ? benefit.icon.localFile.publicURL
                                : benefit.icon_dark.localFile.publicURL
                            }
                            alt={benefit.title}
                          />
                        </div>
                      </StyledCard>
                    </div>
                    <LazyHydrate ssrOnly>
                      <h3>{benefit.title}</h3>
                      <p>{benefit.description}</p>
                    </LazyHydrate>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Benefits
